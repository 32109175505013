import Alpine from 'alpinejs';

Alpine.data('menuMobile', () => ({   
    currentOpenBigMenu: null,
    currentOpenSecondaryMenu: null,
    currentScrollPosition: 0,
    hasScrolled: false,
    isMobileNavOpen: false,

    construct() {
        this.$watch('currentScrollPosition', value => this.checkNavPos());
        this.checkNavPos();
    },
    toggleMobileMenu(event) {
        this.isMobileNavOpen = !this.isMobileNavOpen;
        document.body.classList.toggle('overflow-hidden');

        if (!this.isMobileNavOpen) {
                this.closeMenus();
        }
    },
    outerHeight(elem) {
        var curStyle = elem.currentStyle || window.getComputedStyle(elem);
        outerHeight = elem.offsetHeight;
        outerHeight += parseInt(curStyle.marginTop);
        outerHeight += parseInt(curStyle.marginBottom);

        return outerHeight
    },
    checkNavPos() {
        if (this.$el.getBoundingClientRect().top < 120 && window.scrollY > 120) {
            this.hasScrolled = true;
        } else {
            this.hasScrolled = false;
        }
    },
    toggleBigMenu(event) {
        const menuRef = event.currentTarget.getAttribute('data-menu-target');
        if (menuRef) {
            if (this.currentOpenBigMenu === menuRef) {
                this.closeMenus();
            } else {
                this.currentOpenBigMenu = menuRef;
                this.currentOpenSecondaryMenu = null;
            }
        }
    },
    toggleSecondaryMenu(event) {
        const menuRef = event.currentTarget.getAttribute('data-secondary-menu-target');
        if (menuRef) {
            if (this.currentOpenSecondaryMenu === menuRef) {
                this.closeMenus();
            } else {
                this.currentOpenSecondaryMenu = menuRef;
                this.currentOpenBigMenu = null;
            }
        }
    },
    closeSecondaryMenu(event) {
        const menuRef = event.currentTarget.getAttribute('data-secondary-menu-target');
        if (menuRef) {
            this.currentOpenSecondaryMenu = false;
        }
    },
    isCurrentOpenBigMenu(ref) {
        return this.currentOpenBigMenu === ref ? true : false;
    },
    isCurrentOpenSecondaryMenu(ref) {
        return this.currentOpenSecondaryMenu === ref ? true : false;
    },
    closeMenus() {
        this.currentOpenBigMenu = null;
        this.currentOpenSecondaryMenu = null;
        this.currentOpenExtraMenu = null;
        this.mobileMenuOpen = null;
    }
}))
