import Alpine from 'alpinejs';

Alpine.data('menu', () => ({

    siteHandle: null,
    searchUrl: null,

    isHover: false,
    isSearch: false,
    activeMenu: false,
    activeBigMenu: false,

    searchLoading: false,
    searchQuery: '',
    searchData: null,

    hasSearched() {
        return this.searchData != null;
    },

    hasSearchResults() {
        if (this.searchData == null) {
            return false;
        }

        if (this.searchData.meta.results == 0) {
            return true;
        }

        return true;
    },

    fetchSearchResults() {
        this.searchLoading = true;

        if (this.searchQuery == '') {
            this.searchLoading = false;
            this.searchData = null;
            return;
        }

        this.searchData = null;

        fetch(`/search.json?q=${this.searchQuery}&site=${this.siteHandle}`, {
            headers: { Accept: "application/json" },
        })
        .then((response) => { 
            if (response.ok) { return response.json(); }
            return Promise.reject(response);
        })
        .then((json) => {
            this.searchData = json;
            console.log(this.searchData);
            this.searchLoading = false;
        })
        .catch((response) => {
            response.json().then((json) => {
                this.searchData = null;
                this.searchLoading = false;
            })
        });
    },

    getSearchUrl() {
        return this.searchUrl + '?s=' + this.searchQuery;
    },
    
    setActiveMenu(id) {
        this.isHover = true;
        if (this.activeMenu != id) {
          this.removeActiveBigMenu();
            this.activeMenu = id;
      }
    },
    
    removeActiveMenu(force = false) {
        this.activeMenu = false;
        this.removeActiveBigMenu();
        
      if (this.isHover == false || force) {
        
        this.isHover = false;
      }
    },
    
    hasActiveMenu() {
        return this.activeMenu != false;
    },
    
    isActiveMenu(id) {
        return id == this.activeMenu;
    },
    
    getActiveMenuPosition() {
        if (this.activeMenu == false || this.activeBigMenu != false) {
          return 0;
      }
      var element = document.querySelector('[data-menu-id="'+this.activeMenu+'"]');
      var container = element.closest(".tw-container");
      
      
      var left = (element.getBoundingClientRect().left - container.getBoundingClientRect().left) + (element.offsetWidth / 2) - 150;

      if (left < 0) {
          return 0;
      }
      
      return left;
    },
    
    setActiveBigMenu(id) {
        this.isHover = true;       
      if (this.activeBigMenu != id) {
            this.activeBigMenu = id;
      }
    },
    
    removeActiveBigMenu() {
        this.activeBigMenu = false;
    },
    
    hasActiveBigMenu() {
        return this.activeBigMenu != false;
    },
    
    isActiveBigMenu(id) {
        return id == this.activeBigMenu;
    },
    
    
    startHover() {
        this.isHover = true;
    },
    
    removeHover() {
        this.isHover = false;
    },

    isActiveSearch() {
        return this.isSearch;
    },

    toggleSearch() {
        this.isSearch = !this.isSearch;
        this.removeHover();
        this.removeActiveMenu();
    },

    closeAll() {
        this.isSearch = false;
        this.removeHover();
        this.removeActiveMenu();
    }
}))