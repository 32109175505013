import Alpine from "alpinejs";

Alpine.data('languageBannerLogic', () => ({
    open: false,
    apiResponse: null,
    suggestedLanguage: null,
  
    async init() {
      const entryId = this.$el.getAttribute("data-entry-id");
      const siteId = this.$el.getAttribute("data-site-id");
      if (!entryId || !siteId) return;
      const params = new FormData();
      params.append("entryId", entryId);
      params.append("siteId", siteId)
  
      await fetch("/actions/site-module/country/banner", {
        method: "POST",
        headers: { Accept: "application/json" },
        body: params
      })
          .then(data => {
            return data.json();
          })
          .then(res => {
            console.log("apiresponse set")
            this.apiResponse = res;
            this.apiResponse.statusCode === "OK" && (this.suggestedLanguage = this.apiResponse.country)
          });
  
      this.checkCookie();
    },
  
    checkCookie() {
      let seen = false;
      const cookie = Cookie.get("hasSeenLocalePopup");
      if (cookie) seen = true;
  
      this.open = !seen;
  
      if (this.apiResponse.statusCode === "NOK" || (seen && !this.needsToBeOpen())) {
        document.body.classList.remove("hideCookieBanner")
      }
    },
  
    proceed() {
      const svg = document.querySelector("#loaderSVG").cloneNode(true);
      const container = document.querySelector("#btnYes");
      svg.classList.remove("hidden");
      svg.classList.add("inline")
      container.children[0].appendChild(svg);
  
      Cookie.set("hasSeenLocalePopup", true, { expires: 30 });
      window.location.href = this.suggestedLanguage.url;
    },
    closeLocalePopup() {
      Cookie.set("hasSeenLocalePopup", true, { expires: 30 });
      this.open = false;
      document.body.classList.remove("hideCookieBanner")
    },
  
    needsToBeOpen() {
      return (
          this.apiResponse?.statusCode === "OK" &&
          this.open === true
      )
    }
  }));